import React, {useEffect, useState} from "react";
import {Box, CircularProgress, Typography} from "@mui/material";
import {ConfirmBox} from "./ConfirmBox";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";

const defaultDati = [
    {
        pos_anag: "codice1",
        nome: "Plesh1",
        cognome: "Test1",
        ragioneSociale: "società1",
        ruolo: "posizione1",
        email: "email1",
        cellulare: "Numero di telefono1",
        riscontroGiorno1: null
    },
    {
        pos_anag: "codice2",
        nome: "Plesh2",
        cognome: "Test2",
        ragioneSociale: "società2",
        ruolo: "posizione2",
        email: "email2",
        cellulare: "Numero di telefono2",
        riscontroGiorno1: "Si"
    },
    {
        pos_anag: "codice3",
        nome: "Plesh3",
        cognome: "Test3",
        ragioneSociale: "società3",
        ruolo: "posizione3",
        email: "email3",
        cellulare: "Numero di telefono3",
        riscontroGiorno1: "No"
    }
]

export const ConfirmBlock = () => {
    const {hash, pos} = useParams()
    const [dati, setDati] = useState([])
    const [loading, setLoading] = useState(true)
    const {t} = useTranslation()
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        getData().then(()=>setLoading(false))
    }, [])

    const getData = async () => {
        let api = 'https://api-eventi-ania-u7l2alkhqa-ey.a.run.app/guests/'
        let url = pos ? api + hash + '/'+pos : api + hash
        await axios.get(url)
            .then(response => {
                setDati(response.data.guests)
            })
            .catch(() => navigate('/error', {replace: true}))
    }

    return (
        <Box>
            {loading ?
                <Box sx={{textAlign: 'center'}}>
                    <CircularProgress/>
                </Box>
                :
                <Box>

                    {dati.length >1 && <Typography sx={{mt: 2}} variant={'body1'} fontWeight={'bold'}>
                        {t('riceverai')}
                    </Typography>}
                    {dati.map(d => <ConfirmBox dati={d} key={d.pos_anag}/>)}
                </Box>
            }
        </Box>
    )
}
