import React from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import {red} from "@mui/material/colors";
import {Default} from "../layouts/default";

export const WrongHash = () => {
    const {t} = useTranslation()
    return (
        <Default>
            <Typography textAlign={"center"} color={red[400]} variant={"h4"} fontWeight={'bold'}>
                {t('wrongHash.title')}
            </Typography>
            <Typography textAlign={"center"} variant={"body1"}>
                {t('wrongHash.body')}: <a style={{color:'inherit'}} href={'mailto:insurancesummit@eventiania.it'}>insurancesummit@eventiania.it</a>
            </Typography>
        </Default>
    )
}
