import {createTheme, responsiveFontSizes} from "@mui/material";

export const aniaTheme = responsiveFontSizes(createTheme({
    palette:{
        primary:{
            main:'#002855'
        }
    }
}))

