import './App.css';
import {CssBaseline} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {ConfirmPage} from "./pages/ConfirmPage";
import {WrongHash} from "./pages/WrongHash";
import {Default} from "./layouts/default";
import {Live} from "./pages/Live";
import {Backdoor} from "./pages/Backdoor";
import {NoRegPlayer} from "./pages/NoRegPlayer";
import {BackupLive} from "./pages/BackupLive";

function App() {
    return (
        <div>
            <CssBaseline/>
            <Routes>
                <Route path={'/'} element={<Default/>}/>
                <Route path={'/confirm/:hash'} element={<ConfirmPage/>}/>
                <Route path={'/confirm/:hash/:pos'} element={<ConfirmPage/>}/>
                <Route path={'/error'} element={<WrongHash/>}/>
                <Route path={'/live/:pos_hash'} element={<Live/>}/>
                <Route path={'/backup/:pos_hash'} element={<BackupLive/>}/>
                <Route path={'/live'} element={<Live/>}/>
                <Route path={'/watch/KcPbf6bdGVtHzWGD58XtnIBw6wCnqlbV'} element={<NoRegPlayer/>}/>
            </Routes>
        </div>
    )
}

export default App;
