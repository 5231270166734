import React, {useState} from "react";
import {
    Box,
    Button,
    Card, Checkbox,
    CircularProgress, Dialog, DialogContent,
    FormControlLabel,
    FormGroup,
    Snackbar,
    Stack,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {red} from "@mui/material/colors";
import MuiAlert from '@mui/material/Alert';
import axios from 'axios'
import {useParams} from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ConfirmBox = ({dati}) => {
    const {hash} = useParams()
    const {t} = useTranslation()
    const {nome, cognome, ragioneSociale, ruolo, cellulare, settore} = dati
    const campi = {
        nome: nome,
        cognome: cognome,
        ragioneSociale: ragioneSociale,
        ruolo:ruolo,
        cellulare: cellulare
    }
    const [sending, setSending] = useState(false)
    const [partecipa, setPartecipa] = useState(dati.riscontroGiorno1)
    const [status, setStatus] = useState(dati.status)
    const [error, setError] = useState(false)
    const [privacy, setPrivacy] = useState(false)
    const [open, setOpen] = useState(false)

    const confirm = async (value, code) => {
        setSending(true)
        setError(false)
        const d = {
            pos_anag:code,
            riscontroGiorno1:value,
            emailConferma:hash
        }
        await axios.put('https://api-eventi-ania-u7l2alkhqa-ey.a.run.app/guests/'+dati.pos_anag,d)
            .then((response) => {
                if(response.data.status === 'error'){
                    setError(true)
                } else {
                    setPartecipa(value)
                }
            })
            .catch(() => setError(true))
        setSending(false)
    }

    const partOnline = (value) => {
        setOpen(!open)
        confirm(value, dati.pos_anag).then()
    }

    const checkSettore = () => {
        if(settore === 'Online') {
            confirm('No', dati.pos_anag).then()
        } else {
            setOpen(!open)
        }
    }

    return (
        <Card sx={{my: 4, p: 2}}>
            <Snackbar open={error} autoHideDuration={5000} onClose={() => setError(false)}>
                <Alert onClose={() => setError(false)} severity="error" sx={{ width: '100%' }}>
                    {t('error')}
                </Alert>
            </Snackbar>
            <Typography variant={"h6"} sx={{mb:1}} color={'#005A8E'}>
                {settore === 'Sede' ? t('invitedSite') : t('invitedOnline')}
            </Typography>
            {Object.keys(campi).filter((d) => d !== 'pos_anag' && d!=='riscontroGiorno1').map((d, i) =>
                <Box key={dati.pos_anag + i}>
                    {dati[d] && <span><b>{t(d)}</b>: {dati[d]}</span>}
                </Box>
            )}
            {!status ?
                <Box sx={{mt: 3}}>
                    <Typography color={'#005A8E'} fontWeight={'bold'}>
                        {t('confirmSpecial')} <a style={{color: 'inherit'}} href={'mailto:insurancesummit@eventiania.it'}>insurancesummit@eventiania.it</a>
                    </Typography>
                </Box>
                :
                <Box sx={{mt: 3}}>
                {(partecipa === '' || partecipa === null) ?
                    sending ?
                        <Box sx={{textAlign: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        <Box>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={privacy}
                                                                     onChange={(e) => setPrivacy(e.target.checked)}/>}
                                                  label={t('privacy')}/>
                                <FormControlLabel sx={{alignItems: 'start', py: 2}} control={<Checkbox sx={{pt: '0'}}/>}
                                                  label={<Typography
                                                      variant={"body2"}>{t('subtitle-privacy')}</Typography>}/>
                            </FormGroup>
                            <Stack direction={"row"} justifyContent={"center"} spacing={7}>
                                <Button disabled={!privacy} variant={"contained"}
                                        onClick={() => confirm('Sì', dati.pos_anag)}>
                                    {t('partecipa')}
                                </Button>
                                <Button disabled={!privacy} variant={"outlined"} onClick={checkSettore}>
                                    {t('nonPartecipa')}
                                </Button>
                            </Stack>
                        </Box>
                    :
                    <Typography variant={"h6"} color={partecipa === 'No' ? red[400] : '#005A8E'}
                                textAlign={'center'}>{partecipa === 'Sì' ? t('partecipera') : partecipa === 'Online' ? t('partecipaOnline') : t('nonPartecipera')}</Typography>
                }
                <Dialog open={open} sx={{p: 4}} onClose={() => setOpen(!open)}>
                    <DialogContent>
                        <Typography textAlign={'center'} variant={"h5"} fontWeight={"bold"} sx={{pb: 2}}>
                            {t('online')}
                        </Typography>
                        <Stack direction={"row"} justifyContent={"center"} spacing={7}>
                            <Button disabled={!privacy} variant={"contained"}
                                    onClick={() => partOnline('Online', dati.pos_anag)}>
                                {t('partecipa')}
                            </Button>
                            <Button disabled={!privacy} variant={"outlined"}
                                    onClick={() => partOnline('No', dati.pos_anag)}>
                                {t('nonPartecipa')}
                            </Button>
                        </Stack>
                    </DialogContent>
                </Dialog>
            </Box>}
        </Card>
    )
}
