import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Stack,
    TextareaAutosize,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {LangSwitch} from "../components/LangSwitch";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import titolo from '../assets/images/titolo.png'
import logo from '../assets/images/logo.png'
import tappo from '../assets/images/tappo.jpg'

const mapVideo = {
    en:[
        'https://player.vimeo.com/video/759115048?h=7296e8d907&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
        'https://player.vimeo.com/video/759137591?h=64627fb16a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
        'https://player.vimeo.com/video/759155460?h=8057a13550&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
    ],
    it:[
        'https://player.vimeo.com/video/759122477?h=c447abf655&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
        'https://player.vimeo.com/video/759143305?h=f8977521d8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
        'https://player.vimeo.com/video/759174447?h=0582fb9a17&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
    ]
}

export const Live = () => {
    const {t, i18n} = useTranslation()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up("sm"))
    const [part, setPart] = useState(0)

    document.body.style = 'background: #F1F5F8;';

    return (
        <div className={'live'}>
            <div className={'logo-container'}>
                <img src={logo} className={'live-logo'}/>
            </div>
            <Box sx={{width:'100%', textAlign:'center', background:'#005A8D', position:'static'}}>
                <img className={'title'} src={titolo} style={{width:'100%', padding:'1rem', margin:'auto'}}/>
            </Box>
            <div className={'live-page'}>
                <Stack sx={{width:'100%'}} direction={ matches ? 'column' : 'column-reverse'}>
                    <Box sx={{width:'100%'}}>
                        <Stack spacing={3} sx={{py:1, width:'100%', mt:5, maxWidth:'65rem', margin:'auto', pb:2}} alignItems={'center'} justifyContent={ "space-between"} direction={matches ? "row" : 'column'}>
                            <Box>
                                {t('language')} <LangSwitch/>
                            </Box>
                            <Stack direction={ matches ? "row" : 'column'} spacing={1}>
                                <Button onClick={() => setPart(0)} variant={part === 0 ? 'contained' : 'outlined'}>{t('morning1')}</Button>
                                <Button onClick={() => setPart(1)} variant={part === 1 ? 'contained' : 'outlined'}>{t('morning2')}</Button>
                                <Button onClick={() => setPart(2)} variant={part === 2 ? 'contained' : 'outlined'}>{t('afternoon')}</Button>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box sx={{width:'100%'}}>
                        <Card sx={{ width:'65rem', maxWidth:'100%', margin:'auto'}}>
                            <div
                                style={{position: 'relative', width: '100%', padding: '56.25% 0 0 0', overflowX: 'hidden'}}>
                                <iframe
                                    src={mapVideo[i18n.language][part]}
                                    style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                                    frameBorder="0"
                                    scrolling="no"
                                    allow="autoplay; fullscreen; picture-in-picture" allowFullScreen webkitallowfullscreen
                                    mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
                            </div>
                        </Card>
                    </Box>

                </Stack>
            </div>
        </div>
    )
}
