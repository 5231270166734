import React from "react";
import visual from "../assets/images/visual.jpg";
import {Box, Container, Typography} from "@mui/material";
import {LangSwitch} from "../components/LangSwitch";
import {useTranslation} from "react-i18next";
import {Agenda} from "../components/Agenda";

export const Default = ({children}) => {
    const {t} = useTranslation()
    return (
        <Box sx={{width: '100%'}}>
            <img src={visual} style={{width: '100%'}}/>
            <Container maxWidth={'md'}>
                <Box>
                    {t('language')} <LangSwitch/>
                </Box>
                {children}
                <Box sx={{my: 3}}>
                    <Typography fontWeight={'bold'} color={'#005A8E'} variant={'h6'}>
                        {t('contact')}: <a style={{color: 'inherit'}}
                                           href={'mailto:insurancesummit@eventiania.it'}>insurancesummit@eventiania.it</a>
                    </Typography>
                </Box>
            </Container>
            <Agenda/>
        </Box>
    )
}
