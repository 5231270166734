import React from "react";
import {Button, Card, Container, Typography} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";

export const Agenda = () => {

    const {t} = useTranslation()

    const ag = [
        {
            ora: t('ora1'),
            content: t('content1')
        },
        {
            ora: t('ora2'),
            content: t('content2')
        },
        {
            ora: t('ora3'),
            content: t('content3')
        },
        {
            ora: t('ora4'),
            content: t('content4')
        },
        {
            ora: t('ora5'),
            content: t('content5')
        },
    ]

    return (
        <div className={'agenda'}>
            <Container maxWidth={"sm"} sx={{textAlign:'center', my:2}}>
                <Typography color={'white'} fontSize={'3rem'} textAlign={"center"}>
                    <Trans i18nKey={'agendaTitle'} components={{1:<sup/>}}/>
                </Typography>
                <Typography color={'white'} fontWeight={"bold"} fontSize={'3rem'} textAlign={"center"} sx={{mt:-3}}>
                    {t('agendaSubtitle')}
                </Typography>
                <Button size={"large"} sx={{color:'#000', background:'white !important', my:4}} href={'https://storage.googleapis.com/ania-qr-codes/ANIA_INSURANCE%20SUMMIT_PROGRAMMA.pdf?v='+new Date().getTime()} target={'_blank'}>
                    {t('downloadAgenda')}
                </Button>
                {/*<Card sx={{borderRadius: '1.5rem', padding: '0 5rem', mt: 3}}>
                    {ag.map(a =>
                        <div key={a.content} className={'agendaBlock'} style={{fontWeight: 'bold'}}>
                            <span style={{color: '#002855'}}>{a.ora}</span>
                            <br/>
                            <span style={{color: '#888888'}}>{a.content}</span>
                        </div>
                    )}
                </Card>*/}
            </Container>
        </div>
    )
}
