import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Stack,
    TextareaAutosize,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {LangSwitch} from "../components/LangSwitch";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import titolo from '../assets/images/titolo.png'
import logo from '../assets/images/logo.png'
import tappo from '../assets/images/tappo.jpg'

export const BackupLive = () => {
    const {pos_hash} = useParams()

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'))

    document.body.style = 'background: #F1F5F8;';

    return (
        <div className={'live'}>
            <div className={'logo-container'}>
                <img src={logo} className={'live-logo'}/>
            </div>
            <Box sx={{width:'100%', textAlign:'center', background:'#005A8D', position:'static'}}>
                <img className={'title'} src={titolo} style={{width:'100%', padding:'1rem', margin:'auto'}}/>
            </Box>
            <div className={'live-page'}>
                <Box sx={{p:1, width:'100%', mt:5}}>
                    {<Card sx={{width:'65rem', maxWidth:'100%', margin:'auto'}}>
                        <div
                            style={{position: 'relative', width: '100%', padding: '56.25% 0 0 0', overflowX: 'hidden'}}>
                            <iframe
                                src="https://vimeo.com/event/2496684/embed/3e920ebffe"
                                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                                frameBorder="0"
                                scrolling="no"
                                allow="autoplay; fullscreen; picture-in-picture" allowFullScreen webkitallowfullscreen
                                mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
                        </div>
                    </Card>}
                </Box>
            </div>
        </div>
    )
}
