import React from "react";
import logo from "../assets/images/logo.png";
import {Box, Card} from "@mui/material";
import titolo from "../assets/images/titolo.png";
import tappo from "../assets/images/tappo.jpg";

export const NoRegPlayer = () => {
  return(
      <div className={'live'}>
          <div className={'logo-container'}>
              <img src={logo} className={'live-logo'}/>
          </div>
          <Box sx={{width:'100%', textAlign:'center', background:'#005A8D', position:'static'}}>
              <img className={'title'} src={titolo} style={{width:'100%', padding:'1rem', margin:'auto'}}/>
          </Box>
          <div className={'live-page'}>
              <Box sx={{p:1, width:'100%', mt:5}}>
                  <Card sx={{width:'65rem', maxWidth:'100%', margin:'auto'}}>
                        <div
                            style={{position: 'relative', width: '100%', padding: '56.25% 0 0 0', overflowX: 'hidden'}}>
                            <iframe
                                src="https://onairlive.it/playervjs/plesh"
                                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                                frameBorder="0"
                                scrolling="no"
                                allow="autoplay; fullscreen; picture-in-picture" allowFullScreen webkitallowfullscreen
                                mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
                        </div>
                    </Card>
              </Box>
          </div>
      </div>
  )
}
