import React, {useEffect} from "react";
import {Box, Checkbox, Container, FormControlLabel, Typography} from "@mui/material";
import {ConfirmBlock} from "../components/ConfirmBlock";
import {Trans, useTranslation} from "react-i18next";
import {Default} from "../layouts/default";
import {Agenda} from "../components/Agenda";

export const ConfirmPage = () => {
    const {t} = useTranslation()
    return (
        <Default>
            <Typography textAlign={'center'} sx={{mt: 3, textDecoration: 'underline'}} color={'#002855'}
                        variant={"h5"}
                        fontWeight={'bold'}>{t('title')}</Typography>
            <br/>
            <Typography variant={'body1'}>
                <Trans i18nKey={'subtitle'} components={{1: <a target={"_blank"} href={'https://www.ania.it/web/ania/informativa-privacy-ania-ed-enti-collegati'}/>, 2: <a target={"_blank"} href={'mailto:privacy@ania.it'}/> }}/>
            </Typography>
            <ConfirmBlock/>
        </Default>
    )
}
